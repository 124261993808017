var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"app-ecommerce-details"},[_c('b-alert',{attrs:{"variant":"danger","show":_vm.product === undefined}},[_c('h4',{staticClass:"alert-heading"},[_vm._v("Error fetching product data")]),_c('div',{staticClass:"alert-body"},[_vm._v(" No item found with this item slug. Check "),_c('b-link',{staticClass:"alert-link",attrs:{"to":{ name: 'apps-e-commerce-shop' }}},[_vm._v(" Shop ")]),_vm._v(" for other items. ")],1)]),(_vm.product)?_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"starred"},[_c('i',{staticClass:"fa-star fa-2x",class:_vm.$route.params.starred ? 'fas' : 'far',on:{"click":function($event){return _vm.starProduct({
            store_id: _vm.$route.params.store_id,
            store_product_id: _vm.$route.params.store_product_id,
            is_favorite: _vm.$route.params.starred,
          })}}})]),_c('b-row',{staticClass:"my-2 mx-0"},[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center mb-2 mb-md-0",attrs:{"cols":"12","md":"5","lg":"4"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center w-100"},[_c('b-img',{staticClass:"product-img",attrs:{"src":("" + (_vm.product.product_attributes.logo)),"alt":("Image of " + (_vm.product.product_attributes.name)),"rounded":"","fluid":""}})],1)]),_c('b-col',{attrs:{"cols":"12","md":"7","lg":"8"}},[_c('h4',[_vm._v(_vm._s(_vm.product.product_attributes.name))]),_c('b-card-text',{staticClass:"item-company mb-0"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.product.product_attributes.variant)+" ")])]),(_vm.product.product_attributes.is_edible)?_c('div',{staticClass:"d-flex align-items-center my-1"},[_c('div',{staticClass:"ecommerce-details-price d-flex flex-wrap"},[(
                _vm.product.product_attributes.nutritional_info &&
                _vm.product.product_attributes.is_edible
              )?_c('nutri-score',{attrs:{"nutritional-info":_vm.product.product_attributes.nutritional_info}}):_vm._e()],1)]):_vm._e(),_c('b-card-text',[_vm._v("Producto - "),_c('span',{class:_vm.product.product_attributes.active_status
                ? 'text-success'
                : 'text-danger'},[_vm._v(_vm._s(_vm.product.product_attributes.active_status ? "Activo" : "Desactivado"))])]),(this.product.description)?_c('ul',{staticClass:"mt-2",staticStyle:{"text-align":"left"}},_vm._l((_vm.getDescriptionArray(
              this.product.description
            )),function(item,index){return _c('li',{key:index,staticClass:"mb-50",staticStyle:{"list-style":"inside"}},[_vm._v(" "+_vm._s(_vm.capitalize(item))+" ")])}),0):_vm._e(),(_vm.product.product_attributes.categories_names)?_c('div',{staticClass:"product-color-option pt-0"},[_c('h6',[_vm._v("Categorias")]),_c('ul',{staticClass:"list-unstyled m-0"},_vm._l((_vm.product.product_attributes.categories_names),function(category){return _c('li',{key:("category-" + category),staticClass:"d-inline-block mr-50"},[_c('b-badge',[_vm._v(_vm._s(category))])],1)}),0)]):_vm._e(),_c('hr'),(_vm.product.product_attributes.sku)?_c('div',{staticClass:"d-flex flex-column flex-sm-row"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-barcode fa-3x mr-1"}),_vm._v(" "+_vm._s(_vm.product.product_attributes.sku)+" ")])]):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }